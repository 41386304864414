.root {
  border-radius: 6px;
  border: 1px solid var(--wpp-grey-color-300);
  background-color: var(--wpp-grey-color-000);
  width: 1390px;
}

.main {
  padding: 24px 38px;
  background-color: var(--wpp-grey-color-100);
}

.previewHeader {
  --topbar-with-app-padding: 16px 38px;

  width: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0 24px;

  .buttonsWrapper {
    display: flex;
    align-items: center;

    > * {
      margin-left: 12px;
    }
  }
}

.page {
  width: 100%;
  margin-top: 24px;
  padding: 20px 24px;
  background-color: var(--wpp-grey-color-000);
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
}

.flex, .left, .right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.left {
  > * {
    margin-right: 8px;
  }
}
.right {
  > * {
    margin-left: 8px;
  }
}

.tabs {
  width: 640px;
  margin-bottom: -2px;
}

.divider {
  --wpp-divider-height: 2px;
}
