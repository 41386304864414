.root {
  border-radius: 6px;
  border: 1px solid var(--wpp-grey-color-300);
  background-color: var(--wpp-grey-color-000);
  width: 1390px;
}

.main {
  padding: 24px 38px;
  background-color: var(--wpp-grey-color-100);
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0 24px;

  .buttonsWrapper {
    display: flex;
    align-items: center;

    > * {
      margin-left: 12px;
    }
  }
}

.stepper {
  width: 200px;
  text-align: center;
}

.fullWidthStepper {
  width: 100%;
}

.page {
  width: 1017px;
  margin-top: 24px;
  padding: 20px 24px;
  background-color: var(--wpp-grey-color-000);
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .stepper {
    --stepper-translate-position: 0 !important;
    width: 100%;
  }
}

.previewHeader {
  --topbar-with-app-padding: 16px 38px;

  width: 100%;
}

.selectWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  > * {
    width: 49%;
  }
}
