.homePreview {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--wpp-grey-color-300);
  border-radius: 6px;
  width: 1390px;

  .topbar {
    display: flex;

    .app {
      display: flex;
      align-items: center;

      .logo {
        width: 32px;
        height: 32px;
        margin-left: 20px;
      }
    }

    .actions {
      display: flex;
      align-items: center;

      .helpButton {
        margin-right: 12px;

        .searchIcon {
          color: var(--wpp-icon-color);
        }
      }

      .notification {
        margin: 0 20px;
      }
    }
  }

  .body {
    display: flex;
    padding: 28px 38px 40px 38px;

    .linksAndBrandBlock {
      display: inline-flex;
      flex-direction: column;
      margin: 0 28px 28px 0;
      width: 100%;

      .brandCard {
        .segmentedControl {
          display: flex;
          margin-bottom: 16px;

          &::part(wrapper) {
            width: 100%;
          }
        }

        &::part(card) {
          padding-bottom: 18px;
        }
      }

      .linkCard {
        margin-top: 28px;

        .header {
          margin-bottom: 16px;
        }

        &::part(card) {
          padding-bottom: 22px;
        }
      }

      .brandCard, .linkCard, .workCard {
        width: 320px;

        .listItem {
          &:not(:last-child) {
            margin-bottom: 4px;
          }
        }
      }
    }

    .workCard {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      margin-right: 28px;
      width: 100%;

      .header {
        margin-bottom: 16px;
      }

      .workItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 0 8px 8px;

        .description {
          display: flex;
          flex-direction: column;

          .breadcrumb {
            color: var(--wpp-text-color-info)
          }
        }

        &:not(:last-child) {
          margin-bottom: 4px;
        }
      }

      &::part(card) {
        padding-bottom: 36px;
        width: 320px;
      }
    }

    .topCardsImages {
      display: flex;
      height: 370px;

      .cannesCard {
        width: 435px;
        height: 370px;
        margin-right: 29px;

        &::part(card) {
          background-position: center;
          background-image:url('../../assets/images/cannes.svg');
        }
      }

      .pragueAndHumanCards {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .pragueCard {
          width: 204px;
          height: 171px;

          &::part(card) {
            background-position: center;
            background-image:url('../../assets/images/prague.svg');
          }
        }

        .humanCard {
          width: 204px;
          height: 171px;

          &::part(card) {
            background-position: center;
            background-image:url('../../assets/images/human.svg');
          }
        }
      }
    }

    .bottomCardsImages {
      display: flex;
      flex-wrap: wrap;
      margin-top: 28px;

      .talentCard {
        width: 204px;
        height: 171px;
        margin: 0 28px 28px 0;

        &::part(card) {
          background-position: center;
          background-image:url('../../assets/images/talent.svg');
        }
      }

      .womenCard {
        width: 436px;
        height: 171px;

        &::part(card) {
          background-position: center;
          background-image:url('../../assets/images/women.svg');
        }
      }

      .techCard {
        width: 668px;
        height: 171px;

        &::part(card) {
          background-position: center;
          background-image:url('../../assets/images/tech.svg');
        }
      }
    }

    .cannesCard, .pragueCard, .humanCard, .talentCard, .womenCard, .techCard {
      position: relative;

      .title {
        color: var(--wpp-white-color);
        position: absolute;
        bottom: 16px;
      }
    }
  }
}
